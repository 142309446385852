/* eslint-disable import/no-webpack-loader-syntax */
import Webhooks from '!babel-loader!@mdx-js/loader!./content/webhooks/index.mdx';
import AsyncApiComponent from '@asyncapi/react-component';
import '@asyncapi/react-component/styles/default.min.css';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';
import React from 'react';
import './App.scss';
import ContentArea from './components/ContentArea';
import { StaticScreens } from './content/StaticScreens';
import './default.min.css';
import logo from './img/logo_ctechnology_white_lg.png';

const apiVersion = 'v2.2.0';

function App() {
  const [screen, setScreen] = React.useState('INTRODUCTION');

  const goTo = (where: string) => {
    setScreen(where);
  };

  return (
    <div className="app">
      <div className="app-header">
        <div className="header-left">
          <div className="ctech-logo">
            <img src={logo} alt="c.technology logo"></img>
          </div>
          <div
            className={'header-button ' + (screen === 'INTRODUCTION' ? 'selected' : '')}
            onClick={() => goTo('INTRODUCTION')}
          >
            Introduction
          </div>
          <div
            className={'header-button ' + (screen === 'EXAMPLES' ? 'selected' : '')}
            onClick={() => goTo('EXAMPLES')}
          >
            Code examples
          </div>
          <div className={'header-button ' + (screen === 'REST' ? 'selected' : '')} onClick={() => goTo('REST')}>
            REST API
          </div>
          <div className={'header-button ' + (screen === 'RT' ? 'selected' : '')} onClick={() => goTo('RT')}>
            RT API
          </div>
          <div className={'header-button ' + (screen === 'WEBHOOK' ? 'selected' : '')} onClick={() => goTo('WEBHOOK')}>
            Webhooks
          </div>
          <div
            className={'header-button ' + (screen === 'MANUALS' ? 'selected' : '')}
            onClick={() => (window.location.href = 'https://ctechnology.io/support')}
          >
            Manuals
            <FontAwesomeIcon
              icon={faExternalLinkSquareAlt}
              style={{ marginLeft: '0.6rem', marginBottom: -1, opacity: 0.5 }}
            ></FontAwesomeIcon>
          </div>
          <div
            className={'header-button ' + (screen === 'FAQ' ? 'selected' : '')}
            onClick={() => (window.location.href = 'https://ctechnology.io/faq')}
          >
            FAQ
            <FontAwesomeIcon
              icon={faExternalLinkSquareAlt}
              style={{ marginLeft: '0.6rem', marginBottom: -1, opacity: 0.5 }}
            ></FontAwesomeIcon>
          </div>
        </div>
        <div className="header-right">
          <div className="header-text">{apiVersion}</div>
        </div>
      </div>
      <div className="app-body">
        {screen === 'INTRODUCTION' && (
          <ContentArea
            screens={[
              StaticScreens.introduction.IntroductionScreen,
              StaticScreens.introduction.UsersOrganizationsScreen,
              StaticScreens.introduction.VehiclesScreen,
              StaticScreens.introduction.AppsServicesScreen,
              StaticScreens.introduction.SubscriptionsScreen,
              StaticScreens.introduction.IntegrationsScreen,
            ]}
            contentStyle={{ maxWidth: 960 }}
          ></ContentArea>
        )}
        {screen === 'EXAMPLES' && (
          <ContentArea
            screens={[
              StaticScreens.codeSamples.CodeSamplesScreen,
              StaticScreens.codeSamples.QtCustomMonitoringScreen,
              StaticScreens.codeSamples.DiscordIntegrationScreen,
              StaticScreens.codeSamples.ReactStatusAppScreen,
              StaticScreens.codeSamples.VehicleSharingScreen,
              StaticScreens.codeSamples.DMSIntegrationScreen,
              StaticScreens.codeSamples.SmartHomeIntegrationScreen,
            ]}
            contentStyle={{ maxWidth: 960 }}
          ></ContentArea>
        )}
        {screen === 'REST' && (
          <API apiDescriptionUrl={`/ctechnology-openapi-${apiVersion}.yml`} router="history" hideInternal={true} />
        )}
        {screen === 'RT' && (
          <AsyncApiComponent
            schema={{ url: `/ctechnology-asyncapi-${apiVersion}.yml` }}
            config={{ show: { sidebar: true }, sidebar: { showOperations: 'byDefault' } }}
          ></AsyncApiComponent>
        )}
        {screen === 'WEBHOOK' && (
          <ContentArea screens={[]} contentStyle={{ maxWidth: 960 }}>
            <Webhooks></Webhooks>
          </ContentArea>
        )}
      </div>
    </div>
  );
}

export default App;
